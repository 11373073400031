<template>
  <div class="container">
      <h2 class="mt-4 mb-4">All Businesses</h2>
    <div class="row mt-1">
      <div
        class="col-md-3 col-sm-6 col-12 mt-6"
        v-for="entity in entities"
        :key="entity.uuid"
      >
        <router-link
          :to="{ name: 'Company Profile', params: { entity_name: entity.search_name,name:entity.name.split(' ').join('_') } }"
        >
          <div class="card card-profile">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <img
                      :src="backend_url + entity.logo"
                      class="rounded-circle shadow-lg bg-white"
                      :onerror="`this.src='${$img_placeholder}';`"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div
              class="card-header text-center border-0 pt-6 pt-md-4 pb-0 pb-md-4"
            ></div>

            <div class="card-body pt-0">
              <h2 class="h3 mt-5 text-center">{{ entity.name }}</h2>
              <div class="text-center">
                <div class="h5 font-weight-300">
                  <em class="fas fa-location-arrow mr-2"></em>
                  {{ entity.address }}
                </div>
                <div class="h5 font-weight-300">
                  <em class="fas fa-phone mr-2"></em>
                  {{ entity.phone }}
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      entities: [],
      backend_url: process.env.VUE_APP_BACKEND_URL,
    };
  },
  methods: {
    fetch_approved_entities: function () {
      this.selected = "all_active"; // set selected li element to be active if all industries is selected
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/entities/Approved/public`;
      axios
        .get(url, )
        .then((res) => {
          this.entities = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.fetch_approved_entities();
  },
};
</script>
<style lang="css" scoped>
</style>